.common-tR {
    float: right;
    position: absolute;
    z-index: -1;
    right: -10px;
    top: -10px;
    min-width: 200px;
    min-height: 200px;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border-radius: 10px;
}

.common-bL {
    float: left;
    position: absolute;
    z-index: -1;
    left: -10px;
    bottom: -10px;
    min-width: 200px;
    min-height: 200px;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border-radius: 10px;
}

.adhesive-tR {
    background: #1dc4e9;
}

.adhesive-bL {
    background: #1de9b6;
}


.corrosion-tR {
    background: #536976;
}

.corrosion-bL {
    background:#292e49;
}

.stabas-tR {
    background: rgba(91, 134, 229, 1);
}

.stabas-bL {
    background: rgba(54, 209, 220, 1);
}

.loginBlock {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    opacity:0.98;
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.08);
}

.mb-3 {
    color: #666;
}