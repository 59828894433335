/**======================================================================
=========================================================================
Template Name: Datta Able Free Admin Template
Author: Codedthemes
supports: https://codedthemes.support-hub.io/
File: style.css
=========================================================================
=========================================================================
  == Table of Contents==
	- Generic classes
		-	Margin, Padding, Font class, text align, position, floating, overflow, background class, text color, display class, boarder class
	- Theme Elements
		-	Accordion, Button, tabs, typography, buttons, box shadow, Lable & Badges, Alert, Pagination, Breadcumb, Cards, Collapse,
        -   Carousel, Grid, Progress, Model, tooltip, popover, Datepicker, Gridstack, lightbox, notification, Nestable, pnotify, rating,
        -   Rangeslider, Slider, Syntax Highlighter, Tour, Treeview, Toolbar, Session Timeout, Session idle Timeout, offline, Animation
    - Forms
        -   Forms Elements, Advance Form Control, Validation, Masking, Wizard, Picker, Select
	- Pages
		-   Chat, authentication, Maintenance, Maps, Landingpage messages, task, Todo, Notes, Charts, Icons, Gallery, Editors,
        -   Invoice, Full Calender, File Upload,
=================================================================================
=================================================================================== */
@import "~bootstrap/dist/css/bootstrap";
// General theme contents
@import "partials/variables";
@import "partials/general";
@import "partials/generic";
@import "partials/mixins/function";

// important Element
@import "partials/menu/menu-lite";
@import "partials/widget/widget";

// Theme Element
@import "partials/theme-elements/form";
@import "partials/theme-elements/radiobox-checkbox";
@import "partials/theme-elements/labels-badges";
@import "partials/theme-elements/data-tables";
@import "partials/theme-elements/authentication";
@import "partials/theme-elements/button";
@import "partials/theme-elements/alert";
@import "partials/theme-elements/breadcrumb-pagination";
@import "partials/theme-elements/progress";
@import "partials/theme-elements/tooltip";
@import "partials/theme-elements/popover";
@import "partials/theme-elements/nav";
@import "partials/theme-elements/modal";

// Other
@import "partials/other/chat";
@import "partials/other/prism";
@import "partials/other/switches";

@import "partials/other/chart";
@import "partials/other/icon-lauouts";

@import "partials/third-party/third-party";


.navbar-collapsed .b-title {
  display: none;
}

.fullscreen-enabled {
  overflow: auto;
}


.overlay {
  fill: none;
  pointer-events: all;
}


.d3-tip {
  line-height: 1;
  padding: 10px;
  background: white;
  border: none;
  border-width: 1px;
  border-radius: 0.25rem;
  font-size: 12px;
  box-shadow: 0 0px 10px 0;
}

p {
  font-family: Helvetica;
}

.appVis *,
.appVis::before,
.appVis::after {
  box-sizing: content-box;
}

.icon {
  vertical-align: middle;
  size: 20;
}

.fileDetails {
  svg {
    vertical-align: middle;
    margin-right: 5px;
    padding-bottom: 3px;
    color: #04a9f5;
  }
}

.fileDetails>span {
  margin-right: 50px;
  vertical-align: middle;
}


.clickable-link {
  color: #3f4d67;
  &:hover {
    color: #007bff;
    cursor: pointer;
  }
}

.clickable-link-dark{
  color: #3f4d67;

  &:hover {
    color: #e8edf7;
    cursor: pointer;
  }
}

// AutoSuggest
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 280px;
  height: 38px;
  padding: 10px 20px;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #f1f1f1;
  box-shadow: 0 6px 12px -5px rgba(65, 76, 78, 0.08);
  border-radius: 20px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  max-height:500px;
  overflow:scroll;
  border: 1px solid #f1f1f1;
  box-shadow: 0 6px 12px -5px rgba(65, 76, 78, 0.08);
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: normal;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  font-weight:bold!important;
  color: #777;
  line-height: normal;
}

// Fixed header
body.box-layout .pcoded-header.headerpos-fixed~.pcoded-main-container {
  padding-top: 0;
}

.pcoded-header.headerpos-fixed {
  position: fixed;
  top: 0;
}

.pcoded-header.headerpos-fixed~.pcoded-main-container {
  padding-top: 70px;
}

@media only screen and (max-width: 991px) {
  .pcoded-header.headerpos-fixed~.pcoded-main-container {
    padding-top: 140px;
  }
}

.navbar-collapse{
  background:#F4F7FA;
}


.value{
  color:dodgerblue;
}


:-webkit-scrollbar {
  display: none;
   -ms-overflow-style: none;
   /* IE and Edge */
   scrollbar-width: none;
}

.pcoded-wrapper {
  min-height: 100%!important;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -50px!important;
}

.footer,
.push {
  height: 100px;
}

.footer {
  bottom:0;
  position:absolute;
  background: white;
  height: 70px;
  color:#000;
  font-weight: 300;
  // font-family: Roboto, sans-serif;
  font-size:.7rem;
}

.footer-copyright{
  margin-top:-10px;
}