.logo {
    width: 300px;
    height: 300px;
    textAlign: 'center';
    color: #FFF;
    position: absolute;
    /*left: 50%;*/
    /*top: 50%;*/
    transform: translate3d(-50%, -50%, 0);

}


.logo-adhesive {
    color:#FFF !important;
    background: linear-gradient(-135deg, #1de9b6, #1dc4e9) !important;
}

.logo-corrosion {
    color:#FFF !important;
    background: #D31027 !important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #EA384D, #D31027)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #EA384D, #D31027)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.logo-stabas {
    color:#FFF !important;
    background: rgb(54, 209, 220)  !important;
    background: -moz-linear-gradient(90deg, rgba(54, 209, 220, 1) 0%, rgba(91, 134, 229, 1) 100%) !important;
    background: -webkit-linear-gradient(90deg, rgba(54, 209, 220, 1) 0%, rgba(91, 134, 229, 1) 100%) !important;
    background: linear-gradient(90deg, rgba(54, 209, 220, 1) 0%, rgba(91, 134, 229, 1) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36d1dc", endColorstr="#5b86e5", GradientType=1) !important;
}